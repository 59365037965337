import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressBook,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faBalanceScale,
  faBars,
  faBook,
  faBoxOpen,
  faBuilding,
  faBullhorn,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarWeek,
  faCar,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faClock,
  faCodeBranch,
  faCog,
  faCogs,
  faCoins,
  faComment,
  faCommentAlt,
  faCompress,
  faDog,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileExport,
  faFileImport,
  faFileInvoice,
  faFilter,
  faGift,
  faGlobe,
  faGraduationCap,
  faGripVertical,
  faHandPaper,
  faHandSparkles,
  faHashtag,
  faHeadset,
  faHistory,
  faHome,
  faHouseUser,
  faInbox,
  faInfo,
  faInfoCircle,
  faLink,
  faList,
  faListOl,
  faListUl,
  faLock,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faMap,
  faMapMarkerAlt,
  faMapPin,
  faMinus,
  faMinusCircle,
  faMoneyBill,
  faMoneyBillWave,
  faMoon,
  faPaperclip,
  faPaperPlane,
  faPause,
  faPencilAlt,
  faPercent,
  faPhone,
  faPlane,
  faPlay,
  faPlus,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faReply,
  faRotate,
  faRss,
  faSearch,
  faShieldAlt,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faSitemap,
  faSpinner,
  faStar,
  faStore,
  faStream,
  faSuitcase,
  faSync,
  faTable,
  faTachometerAlt,
  faTag,
  faTasks,
  faTh,
  faThumbtack,
  faTimes,
  faTrash,
  faUndo,
  faUniversity,
  faUnlink,
  faUnlock,
  faUpload,
  faUser,
  faUserClock,
  faUserCog,
  faUserFriends,
  faUserMinus,
  faUserPlus,
  faUsers,
  faUserShield,
  faUserTie,
  faWrench,
  faAt,
  faEquals,
} from '@fortawesome/free-solid-svg-icons';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

library.add(
  faAddressBook,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faBalanceScale,
  faBars,
  faBook,
  faBoxOpen,
  faBuilding,
  faBullhorn,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarWeek,
  faCar,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faClock,
  faCodeBranch,
  faCog,
  faCogs,
  faCoins,
  faComment,
  faCommentAlt,
  faCompress,
  faDog,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileExport,
  faFileImport,
  faFileInvoice,
  faFilter,
  faGift,
  faGlobe,
  faGraduationCap,
  faGripVertical,
  faHandPaper,
  faHandSparkles,
  faHashtag,
  faHeadset,
  faHistory,
  faHome,
  faHouseUser,
  faInbox,
  faInfo,
  faInfoCircle,
  faLink,
  faList,
  faListOl,
  faListUl,
  faLock,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faMap,
  faMapMarkerAlt,
  faMapPin,
  faMinus,
  faMinusCircle,
  faMoneyBill,
  faMoneyBillWave,
  faMoon,
  faPaperclip,
  faPaperPlane,
  faPause,
  faPencilAlt,
  faPercent,
  faPhone,
  faPlane,
  faPlay,
  faPlus,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faReply,
  faRotate,
  faRss,
  faSearch,
  faShieldAlt,
  faShoppingCart,
  faSignInAlt,
  faSignOutAlt,
  faSitemap,
  faSpinner,
  faStar,
  faStore,
  faStream,
  faSuitcase,
  faSync,
  faTable,
  faTachometerAlt,
  faTag,
  faTasks,
  faTh,
  faThumbtack,
  faTimes,
  faTrash,
  faUndo,
  faUniversity,
  faUnlink,
  faUnlock,
  faUpload,
  faUser,
  faUserClock,
  faUserCog,
  faUserFriends,
  faUserMinus,
  faUserPlus,
  faUsers,
  faUserShield,
  faUserTie,
  faWhatsapp,
  faWrench,
  faAt,
  faEquals
);
